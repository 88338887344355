import { Twitter, MailOutline, Instagram, LinkedIn } from '@mui/icons-material';
import { IconLink } from '../IconLink/IconLink';
import { Box, IconButton, useTheme } from '@mui/material';
import { iconLinkSx, tiktokSx } from './SocialLinks.style';
import { Copy } from '../../copy/Copy';
import { flexCenterSx } from '../../styles/Global.style';
import { TikTok } from '../../icons/TikTok/TikTok';

export const SocialLinks = ({ isEmail = true, sx }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ...flexCenterSx, ...sx }}>
      <IconLink
        icon={Instagram}
        href={Copy.generate('Socials.instagram')}
        sx={iconLinkSx(theme)}
        aria="instagram link"
      />
      <IconLink
        icon={Twitter}
        href={Copy.generate('Socials.twitter')}
        sx={iconLinkSx(theme)}
        aria="twitter link"
      />
      <IconLink
        icon={LinkedIn}
        href={Copy.generate('Socials.linkedin')}
        sx={iconLinkSx(theme)}
        aria="linkedin link"
      />
      <IconButton
        onClick={() => window.open(Copy.generate('Socials.tiktok'))}
        sx={tiktokSx}
        aria-label="tiktok link"
      >
        <TikTok sx={iconLinkSx(theme)} />
      </IconButton>
      {isEmail && (
        <IconLink
          icon={MailOutline}
          href={Copy.generate('Global.emailLink')}
          sx={iconLinkSx(theme)}
          aria="email link"
        />
      )}
    </Box>
  );
};
