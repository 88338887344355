export const containerSx = (theme, isReversed, isColumn) => ({
  display: 'flex',
  margin: 'auto',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    padding: '40px',
    width: '98%',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    padding: '40px',
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: isReversed ? 'row-reverse' : isColumn ? 'column' : 'row',
    padding: '80px',
    width: '95%',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: isReversed ? 'row-reverse' : isColumn ? 'column' : 'row',
    padding: '80px',
    width: '75%',
  },
});

export const contentContainerSx = (theme, isReversed, isColumn) => ({
  flex: '1 1 50%',
  justifyContent: 'center !important',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    margin: '0px 0px 48px 0px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0px 0px 48px 0px',
  },
  [theme.breakpoints.up('md')]: {
    margin: isReversed
      ? '0 0 0 80px'
      : isColumn
      ? '0px 0px 48px 0px !important'
      : '0 80px 0 0',
  },
  [theme.breakpoints.up('lg')]: {
    margin: isReversed
      ? '0 0 0 80px'
      : isColumn
      ? '0px 0px 48px 0px !important'
      : '0 80px 0 0',
  },
});

export const logoSx = (theme) => ({
  marginBottom: '48px',
  [theme.breakpoints.up('xs')]: {
    margin: '0 auto 48px auto',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 auto 48px auto',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 0 48px 0',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 0 48px 0',
  },
});

export const contentSx = {
  lineHeight: 1.3,
};

export const imageSx = (theme) => ({
  flex: '1 1 50%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    margin: '0 auto',
  },
  [theme.breakpoints.up('sm')]: {
    width: '50%',
    margin: '0 auto',
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
});
