import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTheme } from '@mui/material';
import { tertiaryHoverSx } from '../../styles/Global.style';

export const Back = ({ fontSize = 60, sx }) => {
  const theme = useTheme();

  return (
    <ArrowBackIosIcon
      sx={{
        color: theme.palette.primary.main,
        ...tertiaryHoverSx(theme),
        fontSize: fontSize,
        cursor: 'pointer',
        ...sx,
      }}
    />
  );
};
