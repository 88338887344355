import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { Text } from '../Text/Text';
import { containerSx, roleSx } from './TeamMemberRole.style';

export const TeamMemberRole = ({ jobRole }) => {
  const theme = useTheme();

  return (
    <Box component="div" sx={containerSx(theme)}>
      <Text text={Copy.generate(jobRole)} sx={roleSx} />
    </Box>
  );
};
