export const contentImgsContainerSx = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};

export const contentImgSx = {
  width: '45%',
  boxSizing: 'border-box',
  margin: '20px 0',
};
