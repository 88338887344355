import { PageContainer } from '../../containers/PageContainer/PageContainer';
import { VideoBanner } from '../../components/VideoBanner/VideoBanner';
import { WhoWeAre } from '../../components/WhoWeAre/WhoWeAre';
import { WorkDone } from '../../components/WorkDone/WorkDone';
import { WhyWeExist } from '../../components/WhyWeExist/WhyWeExist';
import { OurPartners } from '../../components/OurPartners/OurPartners';

export const Home = () => (
  <PageContainer navBottomBorder={false}>
    <VideoBanner />
    <WhoWeAre />
    <WorkDone />
    <WhyWeExist />
    <OurPartners />
  </PageContainer>
);
