import { Copy } from '../copy/Copy';
import { APP_PATHS } from './paths';

export const NAV_LIST_ITEMS = [
  {
    name: Copy.generate('NavList.listItem.1'),
    to: APP_PATHS.team,
  },
  {
    name: Copy.generate('NavList.listItem.2'),
    to: APP_PATHS.work,
  },
  {
    name: Copy.generate('NavList.listItem.3'),
    to: APP_PATHS.services,
  },
];
