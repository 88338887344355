import { AppBar, Box, List, Toolbar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { Burger } from '../../icons/Burger/Burger';
import { Close } from '../../icons/Close/Close';
import { marginLeftAutoSx } from '../../styles/Global.style';
import { IconBtn } from '../IconBtn/IconBtn';
import { Logo } from '../Logo/Logo';
import { NavListItems } from '../NavListItems/NavListItems';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import {
  appBarSx,
  navBarSx,
  navListSx,
  logoSx,
  navListItemSx,
  toolbarSx,
  socialLinksSx,
} from './NavBar.style';

export const NavBar = ({
  isNavMenuOpen,
  toggleMenuBtn,
  isMobile,
  isBottomBorder,
}) => {
  const theme = useTheme();
  const color = theme.palette.secondary.main;
  const navigate = useNavigate();

  const handleLogoClick = () => {
    isNavMenuOpen && toggleMenuBtn();
    navigate(APP_PATHS.home);
  };

  return (
    <Box component="div" sx={navBarSx(theme, isBottomBorder)}>
      <AppBar position="sticky" sx={appBarSx(theme)}>
        <Toolbar disableGutters={!isMobile && true} sx={toolbarSx}>
          <Box component="div" sx={logoSx(theme)} onClick={handleLogoClick}>
            <Logo />
          </Box>

          {isMobile && (
            <Box sx={marginLeftAutoSx}>
              <IconBtn
                icon={isNavMenuOpen ? <Close /> : <Burger />}
                onClick={toggleMenuBtn}
                color={color}
                aria="menu navigation"
              />
            </Box>
          )}

          {!isMobile && (
            <Box component="nav" sx={marginLeftAutoSx}>
              <List sx={navListSx}>
                <NavListItems sx={navListItemSx(theme)} />
                <SocialLinks sx={socialLinksSx} />
              </List>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
