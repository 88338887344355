import { Typography } from '@mui/material';

export const Heading = ({ id, text, level, sx, color }) => (
  <Typography
    id={id}
    variant={`h${level}`}
    sx={{
      color,
      ...sx,
    }}
  >
    {text}
  </Typography>
);
