import { Box, useTheme } from '@mui/material';
import {
  contentCardContainerSx,
  sectionContainerSx,
} from '../../styles/Global.style';
import { ContentCard } from '../ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicLetterColor } from '../../helpers/dynamicLetterColor';
import { PARTNER_LOGOS } from '../../constants/partnerLogos';
import { PartnerLogo } from '../PartnerLogo/PartnerLogo';
import { logoContainerSx, partnerLogosSx } from './OurPartners.style';
import { SectionLink } from '../SectionLink/SectionLink';
import { Animator } from '../Animator/Animator';

export const OurPartners = () => {
  const theme = useTheme();
  const tertiaryColor = theme.palette.tertiary.main;

  return (
    <Box component="section" sx={sectionContainerSx}>
      <ContentCard
        id="our-partners"
        title={Copy.inject('OurPartners.title', {
          R: dynamicLetterColor('R', tertiaryColor),
        })}
        subTitle={Copy.generate('OurPartners.subTitle')}
        sx={contentCardContainerSx(theme)}
        contentContainerSx={{ maxWidth: '1100px' }}
      >
        <Box component="div" sx={logoContainerSx}>
          {PARTNER_LOGOS.map((img, i) => (
            <Animator key={i} type="animate__pulse">
              <PartnerLogo
                id={`our-partners-${i}`}
                img={`partners/${img}`}
                alt={`${img} image`}
                sx={partnerLogosSx(theme)}
              />
            </Animator>
          ))}
        </Box>
      </ContentCard>

      <SectionLink
        id="our-partners"
        link="workWithUs"
        to={Copy.generate('Global.emailLink')}
        linkColor={theme.palette.secondary.main}
      />
    </Box>
  );
};
