export const listContainerSx = (theme) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  cursor: 'pointer',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
});

export const imageContainerSx = {
  margin: '0 16px 16px 16px',
};

export const imageSx = (theme) => ({
  objectFit: 'scale-down',
  height: '300px',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
});
