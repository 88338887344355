export const navBarSx = (theme, isBottomBorder) => ({
  flexGrow: 1,
  borderBottom: `${
    isBottomBorder && 'solid 5px ' + theme.palette.tertiary.main
  }`,
});

export const appBarSx = (theme) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('xs')]: {
    margin: '0 auto',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 auto',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 auto',
    width: '95%',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 auto',
    width: '90%',
  },
});

export const toolbarSx = { height: '100px', marginTop: '20px' };

export const navListSx = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};

export const navListItemSx = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    margin: '0 8px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
    margin: '0 8px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    margin: '0 16px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '18px',
    margin: '0 24px',
  },
});

export const logoSx = (theme) => ({
  cursor: 'pointer',
  [theme.breakpoints.up('xs')]: {
    width: 100,
    height: 67,
  },
  [theme.breakpoints.up('sm')]: {
    width: 120,
    height: 80,
  },
  [theme.breakpoints.up('md')]: {
    width: 120,
    height: 80,
  },
  [theme.breakpoints.up('lg')]: {
    width: 150,
    height: 100,
  },
});

export const socialLinksSx = { paddingBottom: '4px' };
