export const hyperlinkSx = (hoverColor, linkColor) => ({
  color: linkColor,
  fontWeight: 700,
  fontSize: '20px',
  letterSpacing: '4px',
  borderBottom: 'solid',
  paddingLeft: '2px',
  ' &:hover': {
    textDecoration: 'none',
    color: hoverColor,
    cursor: 'pointer',
  },
});
