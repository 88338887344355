export const containerSx = {
  textAlign: 'center',
  margin: '0 auto',
  maxWidth: '300px',
  minWidth: '300px',
};

export const imageSx = {
  width: 208,
  height: 208,
  borderRadius: '50%',
  margin: '0 auto',
};

export const nameSx = { fontWeight: 900, marginTop: '32px' };

export const contentSx = { fontWeight: 500, lineHeight: 1.2 };
