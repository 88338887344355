import { Fade, List, Stack, useTheme } from '@mui/material';
import { NavListItems } from '../NavListItems/NavListItems';
import {
  navListItemSx,
  navListSx,
  navMenuDrawerSx,
} from './NavMenuDrawer.style';
import { SocialLinks } from '../SocialLinks/SocialLinks';

export const NavMenuDrawer = ({ toggleDrawer }) => {
  const theme = useTheme();

  return (
    <Fade in={true} timeout={600}>
      <Stack component="nav" sx={navMenuDrawerSx(theme)}>
        <List sx={navListSx}>
          <NavListItems sx={navListItemSx} onClick={toggleDrawer} />
        </List>

        <SocialLinks />
      </Stack>
    </Fade>
  );
};
