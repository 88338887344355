import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { PartnerLogo } from '../PartnerLogo/PartnerLogo';
import { Text } from '../Text/Text';
import {
  contentSx,
  imageSx,
  contentContainerSx,
  logoSx,
  containerSx,
} from './ContentCardWithImage.style';
import { Image } from '../Image/Image';
import { Animator } from '../Animator/Animator';
import { Heading } from '../Heading/Heading';
import { fontWeight900Sx } from '../../styles/Global.style';

export const ContentCardWithImage = ({
  id,
  title,
  logo,
  content,
  img,
  children,
  logoDimensions,
  isSecondaryCard,
  isReversed,
  isColumn,
}) => {
  const theme = useTheme();
  const backgroundColor = isSecondaryCard && theme.palette.secondary.main;
  const color = isSecondaryCard && theme.palette.primary.main;
  const leftAnimation = isReversed
    ? 'animate__fadeInRight'
    : 'animate__fadeInLeft';
  const rightAnimation = isReversed
    ? 'animate__fadeInLeft'
    : 'animate__fadeInRight';

  return (
    <Box component="section" sx={{ backgroundColor }}>
      <Box component="div" sx={containerSx(theme, isReversed, isColumn)}>
        <Box
          component="div"
          sx={contentContainerSx(theme, isReversed, isColumn)}
        >
          {logo && (
            <Animator type={leftAnimation}>
              <PartnerLogo
                id={`${id}-partner-logo`}
                width={logoDimensions.width}
                height={logoDimensions.height}
                img={logo}
                sx={logoSx(theme)}
                alt={`${logo} image`}
              />
            </Animator>
          )}

          {title && (
            <Animator type={leftAnimation}>
              <Heading
                id={`${id}-title`}
                level={5}
                text={Copy.generate(title)}
                sx={{ ...fontWeight900Sx, marginBottom: '12px' }}
                color={theme.palette.tertiary.main}
              />
            </Animator>
          )}

          <Animator type={leftAnimation}>
            <Text
              id={`${id}-content`}
              text={Copy.generate(content)}
              color={color}
              sx={contentSx}
            />
          </Animator>
        </Box>

        {img && (
          <Box component="div" sx={imageSx(theme)}>
            <Animator type={rightAnimation}>
              <Image id={`${id}-image`} img={img} alt={`${img} image`} />
            </Animator>
          </Box>
        )}

        {children}
      </Box>
    </Box>
  );
};
