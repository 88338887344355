import { useEffect } from 'react';

export const Animator = ({ children, type }) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (!entries || !entries.length) {
        return;
      }

      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate__animated');
          entry.target.classList.add(type);
        } else {
          entry.target.classList.remove('animate__animated');
          entry.target.classList.remove(type);
        }
      });
    });

    observer.observe(document.querySelector(`#${children.props.id}`));
  }, [children, type]);

  return <>{children}</>;
};
