import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material';
import { tertiaryHoverSx } from '../../styles/Global.style';

export const Forward = ({ fontSize = 60 }) => {
  const theme = useTheme();

  return (
    <ArrowForwardIosIcon
      color="primary"
      sx={{
        color: theme.palette.primary.main,
        ...tertiaryHoverSx(theme),
        fontSize: fontSize,
        cursor: 'pointer',
      }}
    />
  );
};
