import { CardMedia } from '@mui/material';

export const Image = ({ id, img, alt, sx }) => (
  <CardMedia
    id={id}
    component="img"
    src={`/assets/${img}.png`}
    alt={alt}
    sx={sx}
  />
);
