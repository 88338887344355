export const containerSx = (theme) => ({
  padding: '40px 0px',
  margin: '0 auto',
  [theme.breakpoints.up('xs')]: {
    width: '900px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '600px',
  },
  [theme.breakpoints.up('md')]: {
    width: '900px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '1200px',
  },
});

export const memberContainerSx = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    margin: '0 50px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 50px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 50px',
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 100px',
  },
});
