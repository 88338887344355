import {
  ListItem as Item,
  ListItem as Li,
  ListItemText,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { listItemSx, listItemTextSx } from './ListItem.style';
import { textAlignCenterSx } from '../../styles/Global.style';

export const ListItem = ({ component = Link, text, to, sx, onClick }) => {
  const theme = useTheme();

  return (
    <Li sx={listItemSx}>
      <Item
        component={component}
        to={to}
        sx={textAlignCenterSx}
        onClick={onClick}
        disablePadding
      >
        <ListItemText
          primary={text}
          primaryTypographyProps={listItemTextSx({
            theme,
            sx,
          })}
        />
      </Item>
    </Li>
  );
};
