import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { APP_PATHS } from '../constants/paths';
import { Work } from '../pages/Work/Work';
import { Team } from '../pages/Team/Team';
import { Services } from '../pages/Services/Services';

export const Routes = () => (
  <Switch>
    <Route path={APP_PATHS.home} element={<Home />} />
    <Route path={APP_PATHS.work} element={<Work />} />
    <Route path={APP_PATHS.team} element={<Team />} />
    <Route path={APP_PATHS.services} element={<Services />} />
    <Route path="*" element={<Navigate to={APP_PATHS.home} replace />} />
  </Switch>
);
