import { CardMedia } from '@mui/material';
import { partnerLogoSx } from './PartnerLogo.style';

export const PartnerLogo = ({
  id,
  img,
  width = 150,
  height = 100,
  sx,
  alt,
}) => (
  <CardMedia
    id={id}
    component="img"
    src={`/assets/${img}.png`}
    alt={alt}
    sx={{ width, height, ...partnerLogoSx, ...sx }}
  />
);
