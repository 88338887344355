export const navMenuDrawerSx = (theme) => ({
  bgcolor: theme.palette.primary.main,
  height: '100%',
});

export const navListSx = {
  height: 'auto !important',
  minHeight: 'calc(100% - 180px)',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
};

export const navListItemSx = {
  fontSize: '24px',
};
