import { tertiaryHoverSx } from '../../styles/Global.style';

export const iconLinkSx = (theme) => ({
  color: theme.palette.secondary.main,
  margin: 'auto 16px',
  cursor: 'pointer',
  ...tertiaryHoverSx(theme),
  [theme.breakpoints.up('xs')]: {
    fontSize: '24px',
    margin: 'auto 8px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
    margin: 'auto 8px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    margin: 'auto 12px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '32px',
    margin: 'auto 12px',
  },
});

export const tiktokSx = {
  padding: 0,
};
