import { Box } from '@mui/material';
import { Video } from '../Video/Video';
import { bannerContainerSx, videoSx } from './VideoBanner.style';
import { useResponsiveBanner } from '../../hooks/useResponsiveVideo/useResponsiveVideo';

export const VideoBanner = () => {
  const { left, height, width, top } = useResponsiveBanner();

  return (
    <Box component="section" sx={bannerContainerSx}>
      <Video
        video="promo"
        sx={{ ...videoSx, left, height, width, top }}
        poster="poster"
      />
    </Box>
  );
};
