import { Box, CardMedia, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { Heading } from '../Heading/Heading';
import { TeamMemberRole } from '../TeamMemberRole/TeamMemberRole';
import { Text } from '../Text/Text';
import { containerSx, contentSx, imageSx, nameSx } from './TeamMember.style';

export const TeamMember = ({ id, name, sx }) => {
  const theme = useTheme();

  return (
    <Box id={id} component="div" sx={{ ...containerSx, ...sx }}>
      <CardMedia
        component="img"
        src={`/assets/${name}.png`}
        alt={`${name} image`}
        sx={imageSx}
      />

      <Heading
        text={Copy.generate(`TeamMember.${name}.name`)}
        level={5}
        color={theme.palette.primary.main}
        sx={nameSx}
      />

      <TeamMemberRole jobRole={`TeamMember.${name}.role`} />

      <Text
        text={Copy.generate(`TeamMember.${name}.content`)}
        color={theme.palette.primary.main}
        sx={contentSx}
      />
    </Box>
  );
};
