export const logoContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'row wrap',
};

export const partnerLogosSx = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    width: 125,
    height: 125,
  },
  [theme.breakpoints.up('sm')]: {
    width: 150,
    height: 150,
  },
});
