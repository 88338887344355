import { IconButton } from '@mui/material';

export const IconBtn = ({
  id,
  children,
  icon,
  onClick,
  color,
  sx,
  disabled,
  aria,
}) => (
  <IconButton
    id={id}
    style={{ color }}
    onClick={onClick}
    sx={sx}
    disableRipple
    disabled={disabled}
    aria-label={aria}
  >
    {icon || children}
  </IconButton>
);
