import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let defaultTheme = createTheme();

defaultTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      default: '#141414',
    },
    primary: {
      main: '#141414',
    },
    secondary: {
      main: '#ffffff',
    },
    tertiary: {
      main: '#DA2229',
    },
    default: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      [defaultTheme.breakpoints.up('xs')]: {
        fontSize: '16px',
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '20px',
      },
    },
    h1: {
      [defaultTheme.breakpoints.up('xs')]: {
        fontSize: '36px',
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: '48px',
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: '56px',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: '86px',
      },
    },
  },
});

defaultTheme = responsiveFontSizes(defaultTheme);

export default defaultTheme;
