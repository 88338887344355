export const tertiaryHoverSx = (theme) => ({
  '&:hover': {
    color: theme.palette.tertiary.main,
  },
});

export const width100Sx = {
  width: '100%',
};

export const marginLeftAutoSx = {
  marginLeft: 'auto',
};

export const marginTopAutoSx = {
  marginTop: 'auto',
};

export const marginBottom24Sx = {
  marginBottom: '24px',
};
export const fontWeight900Sx = { fontWeight: 900 };

export const marginTop40Sx = { marginTop: '40px' };

export const paddingTop40Sx = { paddingTop: '40px' };

export const paddingBottom48Sx = { paddingBottom: '48px' };

export const textAlignCenterSx = { textAlign: 'center' };

export const textCenterBold600Sx = { fontWeight: 600, ...textAlignCenterSx };

export const textCenterBold700Sx = {
  fontWeight: 700,
  ...textAlignCenterSx,
};

export const flexCenterSx = {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
};

export const sectionContainerSx = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 'auto !important',
  minHeight: '100%',
  padding: '20px 0',
};

export const contentCardContainerSx = (theme) => ({
  margin: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    width: '98%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '85%',
  },
  [theme.breakpoints.up('md')]: {
    width: '75%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '65%',
  },
});

export const secondaryContainerSx = (theme) => ({
  ...sectionContainerSx,
  backgroundColor: theme.palette.secondary.main,
});
