import { Link } from '@mui/material';

export const Hyperlink = ({
  id,
  href,
  text,
  variant = 'body1',
  underline = 'none',
  onClick,
  sx,
}) => (
  <Link
    id={id}
    href={href}
    variant={variant}
    underline={underline}
    onClick={onClick}
    sx={sx}
  >
    {text}
  </Link>
);
