import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { marginBottom24Sx } from '../../styles/Global.style';
import { Hyperlink } from '../Hyperlink/Hyperlink';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { Text } from '../Text/Text';
import { copyrightSx, emailSx, sectionContainerSx } from './Footer.style';

export const Footer = ({ id }) => {
  const theme = useTheme();

  return (
    <Box id={id} component="footer" sx={sectionContainerSx(theme)}>
      <Box component="div">
        <SocialLinks isEmail={false} sx={marginBottom24Sx} />
        <Hyperlink
          href={Copy.generate('Global.emailLink')}
          text={Copy.generate('Global.email')}
          sx={emailSx(theme)}
        />
      </Box>

      <Text text={Copy.generate('Footer.copyright')} sx={copyrightSx(theme)} />
    </Box>
  );
};
