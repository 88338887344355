import { Box } from '@mui/material';
import { SectionTitles } from '../../components/SectionTitles/SectionTitles';
import { PageContainer } from '../../containers/PageContainer/PageContainer';
import { Copy } from '../../copy/Copy';
import { paddingTop40Sx } from '../../styles/Global.style';
import { Image } from '../../components/Image/Image';
import { ContentCardWithImage } from '../../components/ContentCardWithImage/ContentCardWithImage';
import { Animator } from '../../components/Animator/Animator';
import { contentImgSx, contentImgsContainerSx } from './Services.style';

export const Services = () => (
  <PageContainer>
    <SectionTitles
      title={Copy.generate('Services.title')}
      subTitle={Copy.generate('Services.subTitle')}
      sx={paddingTop40Sx}
    />

    <ContentCardWithImage
      id="content"
      title="Services.content.title"
      content="Services.content.content"
      isColumn
    >
      <Box component="div" sx={contentImgsContainerSx}>
        {['messi', 'tendulkar', 'serena', 'woods', 'moali', 'jordan'].map(
          (img, i) => (
            <Animator type="animate__pulse" key={i}>
              <Image
                id={`services-content-img-${i}`}
                img={img}
                alt={`${img} image`}
                sx={contentImgSx}
              />
            </Animator>
          ),
        )}
      </Box>
    </ContentCardWithImage>

    <ContentCardWithImage
      id="socials"
      title="Services.socialMedia.title"
      content="Services.socialMedia.content"
      img="socials"
      isSecondaryCard
    />

    <ContentCardWithImage
      id="strategy"
      title="Services.strategy.title"
      content="Services.strategy.content"
      img="strategy"
      isReversed
    />

    <ContentCardWithImage
      id="esports"
      title="Services.esports.title"
      content="Services.esports.content"
      img="esports"
      isSecondaryCard
      isColumn
    />
  </PageContainer>
);
