import { CardMedia } from '@mui/material';

export const Video = ({ video, sx, poster }) => (
  <CardMedia
    component="video"
    src={`/assets/${video}.mov`}
    alt={`${video} video`}
    sx={sx}
    autoPlay
    muted
    loop
    playsInline
    poster={`/assets/${poster}.png`}
  />
);
