export const listItemSx = {
  height: '25%',
  padding: '0px !important',
};

export const listItemTextSx = ({ theme, sx }) => ({
  variant: 'body2',
  sx: {
    color: theme.palette.secondary.main,
    ...sx,
    fontWeight: 500,
    lineHeight: 1,
    display: 'inline-block',
    '&:after': {
      display: 'block',
      content: '""',
      borderBottom: `solid 3px ${theme.palette.tertiary.main}`,
      paddingBottom: '2px',
      transform: 'scaleX(0)',
      transition: 'transform 400ms ease-in-out',
    },
    '&:hover:after': { transform: 'scaleX(1)', transformOrigin: '0% 50%' },
  },
});
