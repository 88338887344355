import { Box, useTheme } from '@mui/material';
import {
  contentCardContainerSx,
  secondaryContainerSx,
} from '../../styles/Global.style';
import { ContentCard } from '../ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { SectionLink } from '../SectionLink/SectionLink';
import { APP_PATHS } from '../../constants/paths';
import { dynamicLetterColor } from '../../helpers/dynamicLetterColor';

export const WhyWeExist = () => {
  const theme = useTheme();
  const tertiaryColor = theme.palette.tertiary.main;

  return (
    <Box component="section" sx={secondaryContainerSx(theme)}>
      <ContentCard
        id="why-we-exist"
        title={Copy.inject('WhyWeExist.title', {
          5: dynamicLetterColor('5', tertiaryColor),
        })}
        subTitle={Copy.generate('WhyWeExist.subTitle')}
        content={Copy.generate('WhyWeExist.content')}
        sx={contentCardContainerSx(theme)}
        isSecondaryCard
      />

      <SectionLink
        id="why-we-exist"
        link="services"
        to={APP_PATHS.services}
        linkColor={theme.palette.primary.main}
      />
    </Box>
  );
};
