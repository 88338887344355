import { Box, useTheme } from '@mui/material';
import { TeamMember } from '../TeamMember/TeamMember';
import { containerSx, memberContainerSx } from './TeamMembersDesktop.style';
import { IconBtn } from '../IconBtn/IconBtn';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Back } from '../../icons/Back/Back';
import { Forward } from '../../icons/Forward/Forward';
import { Animator } from '../Animator/Animator';

const BackArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <IconBtn icon={<Back />} onClick={() => scrollPrev()} disabled={disabled} />
  );
};

const ForwardArrow = () => {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(
    VisibilityContext,
  );

  const [disabled, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible,
  );

  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <IconBtn
      icon={<Forward />}
      onClick={() => scrollNext()}
      disabled={disabled}
    />
  );
};

export const TeamMembersDesktop = () => {
  const theme = useTheme();

  return (
    <Box component="div" sx={containerSx(theme)}>
      <ScrollMenu LeftArrow={BackArrow} RightArrow={ForwardArrow}>
        {['alex', 'hussein', 'adelle', 'ali', 'kevin'].map((member, i) => (
          <Animator key={i} type="animate__fadeIn">
            <TeamMember
              id={`team-member-${member}`}
              itemId={i}
              name={member}
              sx={memberContainerSx(theme)}
            />
          </Animator>
        ))}
      </ScrollMenu>
    </Box>
  );
};
