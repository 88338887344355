export const PARTNER_LOGOS = [
  'ethara',
  'yas',
  'f1',
  'serie-a',
  'mcfc',
  'rangers',
  'rangers-acad',
  'cadiz',
  'go',
  'isl',
  'fuel',
  'yahya',
  'ortiz',
];
