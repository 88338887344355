import { textCenterBold700Sx } from '../../styles/Global.style';

export const titleSx = (color) => ({
  color,
  ...textCenterBold700Sx,
  marginBottom: '16px',
});

export const subTitleSx = (theme) => ({
  color: theme.palette.tertiary.main,
  ...textCenterBold700Sx,
  [theme.breakpoints.up('xs')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '32px',
  },
});
