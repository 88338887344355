import { Box, useTheme } from '@mui/material';
import {
  contentCardContainerSx,
  sectionContainerSx,
} from '../../styles/Global.style';
import { ContentCard } from '../ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicLetterColor } from '../../helpers/dynamicLetterColor';
import { WORK_DONE_LIST } from '../../constants/home';
import { imageContainerSx, imageSx, listContainerSx } from './WorkDone.style';
import { Image } from '../Image/Image';
import { SectionLink } from '../SectionLink/SectionLink';
import { APP_PATHS } from '../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { Animator } from '../Animator/Animator';

export const WorkDone = () => {
  const theme = useTheme();
  const tertiaryColor = theme.palette.tertiary.main;
  const navigate = useNavigate();

  return (
    <Box component="section" sx={sectionContainerSx}>
      <ContentCard
        id="work-done"
        title={Copy.inject('WhatWeveDone.title', {
          R: dynamicLetterColor('R', tertiaryColor),
        })}
        subTitle={Copy.generate('WhatWeveDone.subTitle')}
        sx={contentCardContainerSx(theme)}
      >
        <Box
          component="div"
          sx={listContainerSx(theme)}
          onClick={() => navigate(Copy.generate(APP_PATHS.work))}
        >
          {WORK_DONE_LIST.map((img, i) => (
            <Box key={i} sx={imageContainerSx}>
              <Animator type="animate__pulse">
                <Image
                  id={`work-done-img-${i}`}
                  img={img}
                  alt={`${img} image`}
                  sx={imageSx(theme)}
                />
              </Animator>
            </Box>
          ))}
        </Box>
      </ContentCard>

      <SectionLink
        id="work-done"
        link="work"
        to={APP_PATHS.work}
        linkColor={theme.palette.secondary.main}
      />
    </Box>
  );
};
