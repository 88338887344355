import { Box, useTheme } from '@mui/material';
import { Heading } from '../Heading/Heading';
import { subTitleSx, titleSx } from './SectionTitles.style';
import { Animator } from '../Animator/Animator';

export const SectionTitles = ({ id, title, subTitle, titleColor, sx }) => {
  const theme = useTheme();

  return (
    <Box component="div" sx={sx}>
      <Animator type="animate__fadeInLeft">
        <Heading
          id={`${id}-section-title`}
          text={title}
          level={1}
          sx={titleSx(titleColor)}
        />
      </Animator>

      <Animator type="animate__fadeInRight">
        <Heading
          id={`${id}-section-sub-title`}
          text={subTitle}
          level={2}
          sx={subTitleSx(theme)}
        />
      </Animator>
    </Box>
  );
};
