import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { Hyperlink } from '../Hyperlink/Hyperlink';
import { hyperlinkSx } from './SectionLink.style';
import { textAlignCenterSx } from '../../styles/Global.style';
import { Animator } from '../Animator/Animator';

export const SectionLink = ({ id, link, to, linkColor }) => {
  const theme = useTheme();
  const hoverColor = theme.palette.tertiary.main;

  return (
    <Box component="div" sx={textAlignCenterSx}>
      <Animator type="animate__fadeIn">
        <Hyperlink
          id={`${id}-section-link`}
          href={to}
          text={Copy.generate(`Links.${link}`)}
          variant="body2"
          sx={hyperlinkSx(hoverColor, linkColor)}
        />
      </Animator>
    </Box>
  );
};
