export const sectionContainerSx = (theme) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '176px',
  paddingTop: '24px',
  borderTop: `${'solid 5px ' + theme.palette.tertiary.main}`,
});

export const emailSx = (theme) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  fontSize: '16px !important',
});

export const copyrightSx = (theme) => ({
  fontWeight: 600,
  fontSize: '12px !important',
  color: theme.palette.tertiary.main,
  marginBottom: '32px',
});
