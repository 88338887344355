import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes } from '../../routes/routes';
import defaultTheme from '../../themes/defaultTheme';
import { ScrollToTop } from '../../components/ScrollToTop/ScrollToTop';

export const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <ScrollToTop>
      <Routes />
    </ScrollToTop>
  </ThemeProvider>
);
