import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../ContentCard/ContentCard';
import { SectionLink } from '../SectionLink/SectionLink';
import { APP_PATHS } from '../../constants/paths';
import { Copy } from '../../copy/Copy';
import { dynamicLetterColor } from '../../helpers/dynamicLetterColor';
import {
  contentCardContainerSx,
  secondaryContainerSx,
} from '../../styles/Global.style';

export const WhoWeAre = () => {
  const theme = useTheme();
  const tertiaryColor = theme.palette.tertiary.main;

  return (
    <Box component="section" sx={secondaryContainerSx(theme)}>
      <ContentCard
        id="who-we-are"
        title={Copy.inject('WhoWeAre.title', {
          R: dynamicLetterColor('R', tertiaryColor),
        })}
        subTitle={Copy.generate('WhoWeAre.subTitle')}
        content={Copy.inject('WhoWeAre.content', {
          THE: dynamicLetterColor('THE', tertiaryColor),
        })}
        sx={contentCardContainerSx(theme)}
        isSecondaryCard
      />

      <SectionLink
        id="who-we-are"
        link="team"
        to={APP_PATHS.team}
        linkColor={theme.palette.primary.main}
      />
    </Box>
  );
};
