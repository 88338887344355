import { Box, useTheme } from '@mui/material';
import { Text } from '../Text/Text';
import { SectionTitles } from '../SectionTitles/SectionTitles';
import { contentSx } from './ContentCard.style';
import { Animator } from '../Animator/Animator';

export const ContentCard = ({
  id,
  title,
  subTitle,
  content,
  children,
  contentContainerSx,
  sx,
  isSecondaryCard,
}) => {
  const theme = useTheme();
  const color = isSecondaryCard && theme.palette.primary.main;

  return (
    <Box component="div" sx={sx}>
      <SectionTitles
        id={id}
        title={title}
        subTitle={subTitle}
        titleColor={color}
      />

      <Box component="div" sx={{ ...contentSx, ...contentContainerSx }}>
        {content && (
          <Animator type="animate__pulse">
            <Text id={`${id}-content`} text={content} color={color} />
          </Animator>
        )}
        {children}
      </Box>
    </Box>
  );
};
