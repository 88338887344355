import { PageContainer } from '../../containers/PageContainer/PageContainer';
import { ContentCardWithImage } from '../../components/ContentCardWithImage/ContentCardWithImage';
import { SectionTitles } from '../../components/SectionTitles/SectionTitles';
import {
  workCadizLogoDimensions,
  workFarisLogoDimensions,
  workYasLogoDimensions,
} from './Work.styles';
import { Copy } from '../../copy/Copy';
import { paddingTop40Sx } from '../../styles/Global.style';

export const Work = () => (
  <PageContainer>
    <SectionTitles
      id="work"
      title={Copy.generate('Work.title')}
      subTitle={Copy.generate('Work.subTitle')}
      sx={paddingTop40Sx}
    />

    <ContentCardWithImage
      id="yas"
      logo="yas"
      content="Work.yasMarina.content"
      img="yasImg"
      logoDimensions={workYasLogoDimensions}
    />

    <ContentCardWithImage
      id="cadiz"
      logo="cadiz"
      content="Work.cadiz.content"
      img="cadizImg"
      logoDimensions={workCadizLogoDimensions}
      isSecondaryCard
    />

    <ContentCardWithImage
      id="faris"
      logo="faris"
      content="Work.faris.content"
      img="farisImg"
      logoDimensions={workFarisLogoDimensions}
    />
  </PageContainer>
);
