import { useEffect, useState } from 'react';
import { useWindowSize } from '../useWindowSize/useWindowSize';

export const useResponsiveBanner = () => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const { windowSize } = useWindowSize();
  const windowWidth = windowSize.width;
  const windowHeight = windowSize.height;
  const [width, setWidth] = useState(windowWidth);
  const [height, setHeight] = useState(windowHeight);

  useEffect(() => {
    const videoAspectRatio = 16 / 9;
    const minWidth = windowHeight * videoAspectRatio;
    const minHeight = windowWidth / videoAspectRatio;

    const calcLeft = (width) => {
      if (width < minWidth) {
        return (windowWidth - minWidth) / 2;
      }
      return 0;
    };

    const calcTop = (height) => {
      if (height < minHeight) {
        return (windowHeight - minHeight) / 2;
      }
      return 0;
    };

    const calcWidth = (width) => {
      if (width >= minWidth) {
        return width;
      }
      return minWidth;
    };

    const calcHeight = (height) => {
      if (height >= minHeight) {
        return height;
      }
      return minHeight;
    };

    const calcDimensions = () => {
      setLeft(calcLeft(windowWidth));
      setWidth(calcWidth(windowWidth));
      setHeight(calcHeight(windowHeight));
      setTop(calcTop(windowHeight));
    };

    calcDimensions();
  }, [windowWidth, windowHeight]);

  return { left, height, width, top };
};
