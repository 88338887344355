import { sectionContainerSx } from '../../styles/Global.style';

export const bannerContainerSx = {
  ...sectionContainerSx,
  minHeight: 'calc(100% - 120px)',
};

export const videoSx = {
  position: 'absolute',
};
