import { Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { TeamMember } from '../TeamMember/TeamMember';
import { containerSx } from './TeamMembersMobile.style';

export const TeamMembersMobile = () => (
  <Box component="div" sx={containerSx}>
    <SwipeableViews>
      <TeamMember name="alex" />
      <TeamMember name="hussein" />
      <TeamMember name="adelle" />
      <TeamMember name="ali" />
      <TeamMember name="kevin" />
    </SwipeableViews>
  </Box>
);
