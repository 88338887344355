import { Box, useTheme } from '@mui/material';
import { SectionTitles } from '../../components/SectionTitles/SectionTitles';
import { PageContainer } from '../../containers/PageContainer/PageContainer';
import { TeamMembersMobile } from '../../components/TeamMembersMobile/TeamMembersMobile';
import { TeamMembersDesktop } from '../../components/TeamMembersDesktop/TeamMembersDesktop';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { dynamicLetterColor } from '../../helpers/dynamicLetterColor';
import { Copy } from '../../copy/Copy';
import { sectionContainerSx } from './Team.style';
import { paddingTop40Sx } from '../../styles/Global.style';

export const Team = () => {
  const theme = useTheme();
  const { isMobile } = useWindowSize();

  return (
    <PageContainer>
      <Box component="section" sx={sectionContainerSx(theme)}>
        <SectionTitles
          title={Copy.inject('OurTeam.title', {
            R: dynamicLetterColor('R', theme.palette.tertiary.main),
          })}
          subTitle={Copy.generate('OurTeam.subTitle')}
          titleColor={theme.palette.primary.main}
          sx={paddingTop40Sx}
        />

        {isMobile ? <TeamMembersMobile /> : <TeamMembersDesktop />}
      </Box>
    </PageContainer>
  );
};
