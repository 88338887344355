import React, { useState } from 'react';
import { NavMenuDrawer } from '../../components/NavMenuDrawer/NavMenuDrawer';
import { NavBar } from '../../components/NavBar/NavBar';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { Box, useTheme } from '@mui/material';
import { pageContainerSx } from './PageContainer.style';
import { Footer } from '../../components/Footer/Footer';
import { Animator } from '../../components/Animator/Animator';

export const PageContainer = ({
  children,
  navBottomBorder = true,
  isFooter = true,
}) => {
  const theme = useTheme();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const toggle = () => setIsNavMenuOpen(!isNavMenuOpen);

  return (
    <Box component="div" sx={pageContainerSx(theme)}>
      <NavBar
        isNavMenuOpen={isNavMenuOpen}
        toggleMenuBtn={toggle}
        isMobile={isMobile}
        isBottomBorder={!isNavMenuOpen && navBottomBorder}
      />

      {isNavMenuOpen && isMobile ? (
        <NavMenuDrawer toggleDrawer={toggle} />
      ) : (
        <>
          {children}
          {isFooter && (
            <Animator type="animate__pulse">
              <Footer id="page-container-footer" />
            </Animator>
          )}
        </>
      )}
    </Box>
  );
};
