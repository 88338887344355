import { useTheme } from '@mui/material';
import { NAV_LIST_ITEMS } from '../../constants/navListItems';
import { ListItem } from '../ListItem/ListItem';

export const NavListItems = ({ sx, onClick }) => {
  const theme = useTheme();

  return (
    <>
      {NAV_LIST_ITEMS?.map((item) => (
        <ListItem
          key={item.name}
          text={item.name}
          to={item.to}
          theme={theme}
          sx={sx}
          onClick={onClick}
        />
      ))}
    </>
  );
};
